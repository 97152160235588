import { create } from "zustand";
import { InvoiceMemoState } from "../models/MemoState";
import { api, ServiceTypeName } from "@enerbit/base";
import { MemoDetail } from "../models/DetailMemo";
import { Nabla } from "../models/InvoiceMemo";
import { Component } from "../models/Invoice";

export const useInvoiceMemoStore = create<InvoiceMemoState>((set, _get) => ({
    isLoadingInvoiceMemoRequest: false,
    isLoadingInvoiceMemoDocumentRequest: false,
    isLoadingInvoiceDocumentRequest: false,
    openHistoryDialog: false,
    invoiceMemoDetailError: "",
    invoiceMemoTypeName: ServiceTypeName.electricitySupplyServices,
    invoiceMemoName: "",
    invoiceMemoId: "",
    invoiceMemo: {},
    nabla: [],
    memoHaveReactive: false,
    isLoadingResettlementExplainRequest: false,
    memoResettlementExplain: "",

    setOpenHistoryDialog: (value: boolean) => set({ openHistoryDialog: value }),
    setInvoiceTypeName: (name: ServiceTypeName) =>
        set({ invoiceMemoTypeName: name }),
    setInvoiceMemoName: (name: string) => set({ invoiceMemoName: name }),
    setInvoiceMemoId: (invoiceMemoId: string) =>
        set({ invoiceMemoId: invoiceMemoId }),

    getMemoDetail: async ({
        invoiceMemoId,
        path,
    }: {
        invoiceMemoId: string;
        path: string;
    }) => {
        set({ isLoadingInvoiceMemoRequest: true, invoiceMemo: {} });

        try {
            const { data } = await api.get<MemoDetail>(
                `${path}${invoiceMemoId}`,
            );
            const componentCodesAdjusted: { [key: string]: Component } = {};

            data.pareint_invoice?.components!.forEach((item) => {
                componentCodesAdjusted[item.component_code!] = item;
            });

            const nablaList: Nabla[] = data.invoice_memo
                ?.components!.map((item) => {
                    const componentCode = item.componentCode!.replace(
                        "-ADJ",
                        "",
                    );
                    const correspondingItem =
                        componentCodesAdjusted[componentCode];

                    if (correspondingItem) {
                        return {
                            name: item.name,
                            component_code: item.componentCode,
                            invoice_initial_amount: correspondingItem.amount,
                            resettlement:
                                (item.amount ?? 0) +
                                (correspondingItem.amount ?? 0),
                            difference: item.amount || 0,
                        };
                    } else {
                        return {
                            name: item.name,
                            component_code: item.componentCode,
                            invoice_initial_amount: 0,
                            resettlement: (item.amount ?? 0) + 0,
                            difference: item.amount || 0,
                        };
                    }
                })
                .filter((item) => item !== null) as unknown as Nabla[];

            nablaList.map((item) => {
                if (
                    item.component_code == "PREI-001-ADJ" ||
                    item.component_code == "PREE-001-ADJ"
                ) {
                    if (item.difference ?? 0 > 0) {
                        set({ memoHaveReactive: true });
                    }
                }
            });

            data.nabla?.children?.map((item) => {
                if (
                    item.data?.minuend?.settlement_component?.code == "AEI-001"
                ) {
                    if (
                        item.data?.minuend?.quantity !=
                        item.data?.subtrahend?.quantity
                    ) {
                        nablaList.push({
                            name: "Consumo",
                            component_code:
                                item.data?.subtrahend?.settlement_component
                                    ?.code,
                            invoice_initial_amount:
                                item.data?.minuend?.quantity,
                            resettlement: item.data?.subtrahend?.quantity,
                            difference:
                                (item.data?.minuend?.quantity ?? 0) -
                                (item.data?.subtrahend?.quantity ?? 0),
                        });
                    }
                }
            });

            set({ nabla: nablaList });

            set({ isLoadingInvoiceMemoRequest: false, invoiceMemo: data });
        } catch (_error) {
            set({
                isLoadingInvoiceMemoRequest: false,
                invoiceMemoDetailError: "error fetching memo detail",
            });
        }
    },

    getDocument: async ({
        documentId,
        isMemo,
    }: {
        documentId: string;
        isMemo: boolean;
    }) => {
        if (isMemo) {
            set({ isLoadingInvoiceMemoDocumentRequest: true });
        } else {
            set({ isLoadingInvoiceDocumentRequest: true });
        }

        try {
            let response = await api.get(
                isMemo
                    ? `/billing/memos/${documentId}/document`
                    : `/billing/invoices/${documentId}/document/`,
                {
                    responseType: "arraybuffer",
                },
            );
            const file = new Blob([response.data], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement("a");
            link.href = fileURL;
            link.download = `${documentId}.pdf`;
            link.click();
            URL.revokeObjectURL(fileURL);
            if (isMemo) {
                set({ isLoadingInvoiceMemoDocumentRequest: false });
            } else {
                set({ isLoadingInvoiceDocumentRequest: false });
            }
        } catch (_error) {
            if (isMemo) {
                set({ isLoadingInvoiceMemoDocumentRequest: false });
            } else {
                set({ isLoadingInvoiceDocumentRequest: false });
            }
        }
    },

    getResettlementExplain: async ({
        invoiceMemoId,
    }: {
        invoiceMemoId: string;
    }) => {
        set({ isLoadingResettlementExplainRequest: true });
        try {
            const { data } = await api.get<string>(
                `/electricity-supply-service/billing/memo-nl-explain/${invoiceMemoId}`,
            );
            set({
                isLoadingResettlementExplainRequest: false,
                memoResettlementExplain: data,
            });
        } catch (_error) {
            set({
                isLoadingResettlementExplainRequest: false,
                invoiceMemoDetailError: "error fetching memo detail",
            });
        }
    },
}));
